import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => {
    return {
      checkReadOne: 0,
      paymentType: 1,
      price: 0,
      errors: {
        phone: false,
        email: false,
        code: false
      },
      selectType: 1,
      isBusyPlace: false,
      preload: false,
      upgradeDescription: {},
      form: {
        phone: "",
        email: "",
        code: ""
      },
      order: {}
    };
  },
  watch: {
    selectType() {
      this.getTariff();
    },
    selectedColumn() {
      this.getTariff();
    }
  },
  computed: {
    ...mapState("flight", {
      planeAirSeats: state => state.planeAirSeats
    }),
    checkSubmit() {
      if (this.checkReadOne) {
        return true;
      }
      return false;
    },
    checkRowUpgrade() {
      const result =
        this.selectedColumn.length ===
          this.selectedColumn.filter(seat => {
            if (seat.nameHash === this.user.nameHash) {
              return true;
            }
            if (seat.seatAvailability === 0 || seat.nameHash || seat.disabled) {
              return false;
            } else {
              return true;
            }
          }).length && this.selectedColumn.length > 2;
      if (!result && this.selectType === 3) {
        this.setSelectType(1);
      }
      return result;
    },
    checkNextSeat() {
      let indexArr = this.selectedColumn.findIndex(
        seat =>
          seat.nameHash === this.user.nameHash &&
          this.user.seat === seat.seatNumber
      );
      if (indexArr === -1) {
        if (this.selectType === 2) {
          this.setSelectType(1);
        }
        return false;
      } else {
        let newArr = [];
        if (
          this.selectedColumn[indexArr - 1] &&
          this.selectedColumn[indexArr - 1].seatNumber === this.selected
        ) {
          newArr.push(this.selectedColumn[indexArr - 1]);
        }
        if (
          this.selectedColumn[indexArr + 1] &&
          this.selectedColumn[indexArr + 1].seatNumber === this.selected
        ) {
          newArr.push(this.selectedColumn[indexArr + 1]);
        }
        if (
          newArr.filter(seat => {
            if (seat.seatAvailability === 0 || seat.nameHash || seat.disabled) {
              if (this.selectType === 2) {
                this.setSelectType(1);
              }
              return false;
            }
            return true;
          }).length >= 1
        ) {
          return true;
        }
      }
    },
    ...mapState("user", {
      user: state => state.user
    }),
    ...mapState("order", {
      orders: state => state.index,
      layout: state => state.layout
    }),
    ...mapState("flight", {
      serverName: state => state.serverName
    }),
    getLayoutUpgrade() {
      return this.layout.find(item => item.category === "upgradePlace");
    },
    emailValidate() {
      let emailValidator = new RegExp(
        "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
      );
      let validEmail = emailValidator.test(this.form.email);
      if (!validEmail) {
        return false;
      }

      return true;
    },
    phoneValidate() {
      let validator = new RegExp("^[0-9]+$");
      let test = validator.test(this.form.phone);
      if (!test || this.form.phone.length < 10) {
        return false;
      }

      return true;
    },
    codeValidate() {
      return this.form.code.length >= 3;
    },
    validateForm() {
      if (
        this.form.email.length > 1 &&
        this.form.phone.length > 10 &&
        this.form.code.length >= 3
      ) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.selected) {
      this.getTariff();
    }
  },
  methods: {
    setSelectType(value) {
      this.selectType = value;
    },
    completeOrder(payload) {
      let order = this.orders.find(it => it.guid === payload.guid);
      this.completeOrderGuid({
        guid: payload.guid,
        state: this.serverName.externalControl ? "paid" : "completed"
      });
      if (
        !order.upgradeSeat.places.find(item => item === order.seat) &&
        order.upgradeSeat.typeUpgrade !== 2
      ) {
        this.setUserEntity([
          "user",
          { ...this.user, seat: order.upgradeSeat.upgradeSeat }
        ]);
      }
    },
    ...mapActions("order", {
      setOrder: "setOrderUpdatePlace",
      cancelOrder: "cancelOrder",
      completeOrderGuid: "completeOrderGuid"
    }),
    ...mapMutations("user", {
      setUserEntity: "SET_ENTITY"
    }),
    ...mapMutations("flight", {
      updatePlaneByIndex: "UPDATE_PLANE_BY_INDEX"
    }),
    async getTariff() {
      if (this.selected) {
        let payload = {
          seat: this.user.seat,
          upgradeSeat: this.selected,
          typeUpgrade: this.selectType,
          nameHash: this.user.nameHash,
          places: [this.selected]
        };
        if (this.selectType === 3) {
          payload.places = this.selectedColumn.map(seat => seat.seatNumber);
        }
        this.preload = true;
        let { data } = await this.axios.post("/api/board/getTariff", payload);
        this.preload = false;
        this.price = data.price;
        this.upgradeDescription = data.upgradeDescription;
        this.isBusyPlace = data.isBusyPlace;
        if (this.isBusyPlace) {
          const indexPlace = this.planeAirSeats.findIndex(
            p => p.seat === this.selected
          );
          if (indexPlace !== -1) {
            this.updatePlaneByIndex([{ disabled: true }, indexPlace]);
          }
        }
      }
    },
    async closePreloader() {
      await this.cancelOrder({ id: this.getLayoutUpgrade.id });
      this.$emit("selected", false);
      this.isBusyPlace = false;
    },
    async closeStepOne() {
      this.selectType = 1;
      this.$emit("selected", false);
    },
    async submitStepOne() {
      this.preload = true;
      this.order = {};
      await this.cancelOrder({ id: this.getLayoutUpgrade.id });
      let payload = {
        typeUpgrade: this.selectType,
        upgradeSeat: this.selected,
        ids: [{ id: this.getLayoutUpgrade.id }], //todo Уточнить что сюда писать
        price: this.price,
        places: [this.selected]
      };
      if (this.selectType === 3) {
        payload.places = this.selectedColumn.map(seat => seat.seatNumber);
      }
      this.order = await this.setOrder(payload);
      setTimeout(async () => {
        const { data } = await this.axios.post("/api/board/checkPlace", {
          place: this.selected
        });

        const res = data.find(
          item => item.guid === this.order.guid && item.state === "processing"
        );
        this.preload = false;
        if (!res) {
          this.isBusyPlace = true;
          const indexPlace = this.planeAirSeats.findIndex(
            p => p.seat === this.selected
          );
          if (indexPlace !== -1) {
            this.updatePlaneByIndex([{ disabled: true }, indexPlace]);
          }
        }
      }, 5000);
    },
    async validateAndPayment() {
      this.errors.email = !(this.form.email.length > 1 && this.emailValidate);
      this.errors.phone = !(this.form.phone.length > 1 && this.phoneValidate);
      this.errors.code = !(this.form.code.length > 1 && this.codeValidate);
      if (!this.errors.email && !this.errors.phone && !this.errors.code) {
        try {
          const order = this.orders.find(order =>
            order.items.find(it => it.id === this.getLayoutUpgrade.id)
          );
          const { data } = await this.axios.post("/api/board/checkPlace", {
            place: this.selected
          });

          const res = data.find(
            item => item.guid === order.guid && item.state === "processing"
          );
          if (!res) {
            this.isBusyPlace = true;
            const indexPlace = this.planeAirSeats.findIndex(
              p => p.seat === this.selected
            );
            if (indexPlace !== -1) {
              this.updatePlaneByIndex([{ disabled: true }, indexPlace]);
            }
            return;
          }
          await this.axios.post("/api/board/checkPin", {
            seat: this.user.seat,
            typeUpgrade: this.selectType,
            guid: order.guid,
            email: this.form.email,
            pin: +this.form.code,
            telephone: this.form.phone,
            payType: 1
          });
          this.errors.code = false;
          this.$emit("update", {
            price: this.price,
            paymentType: this.paymentType,
            email: this.form.email,
            phone: this.form.phone,
            guid: order.guid,
            selected: order.upgradeSeat.places.join(",")
          });
        } catch (e) {
          this.errors.code = true;
        }
      }
    }
  }
};
